<template>
  <div>
    <template v-if="isApplicant">
      <b-card class="card-custom rounded-top-0" body-class="pt-15">
        <b-row v-if="transaction.id">
          <b-col :md="8" class="mx-auto">
            <b-card class="box-shadow-unset card-custom" header-class="d-flex border-bottom-0">
              <template v-slot:header>
                <div class="card-title flex-column align-items-start">
                  #{{ transaction.id }}
                  <p class="text-primary">{{ get(transaction, 'attributes.paid_date') }}</p>
                </div>
                <div class="card-toolbar d-flex">
                  <b-btn variant="primary" @click="printInvoice()">
                    <i class="flaticon2-print"></i>
                    Print
                  </b-btn>
                </div>
              </template>
              <!-- <div class="pb-8">
              <h2 class="font-weight-boldest">Your Order is Completed!</h2>
              <p class="lead">
                Thank you for your order! Your order is being processed and will be processed as soon as possible.
                <br />
                You will receive an email confirmation when your order is completed.
                <br />
                Confirmation Will Be Sent to
                <strong>
                  {{ get(transaction, 'attributes.applicant.data.attributes.first_name') }}
                  {{ get(transaction, 'attributes.applicant.data.attributes.last_name') }}
                </strong>
              </p>
            </div> -->
              <div class="table-responsive mb-0">
                <table class="table">
                  <tbody>
                    <template v-for="header in invoiceHeaders">
                      <tr :key="header.key" class="border-bottom-0">
                        <td class="font-weight-boldest border-top-0 pl-0 py-4">{{ header.name }}</td>
                        <td class="border-top-0 pr-0 py-4 text-right">
                          <span
                            :class="[header.classes || 'd-block d-md-inline font-weight-bolder text-primary ml-md-10']"
                          >
                            {{
                              moneyDollarForamter(
                                get(transaction, 'attributes.application.data.attributes.' + header.key, 0)
                              )
                            }}
                          </span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <div v-else-if="loading" class="position-relative" style="height:400px;">
          <spinner bg-transparent />
        </div>
        <div v-else class="text-center my-10">
          You don't have any transactions
        </div>
      </b-card>
    </template>
    <template v-else>
      <cs-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :pagination="pagination"
        hide-header
        base-classes="card card-custom card-stretch gutter-b rounded-top-0"
        table-container-classes="card-body pt-15"
        @filtered="handelFiltering"
      >
        <template #[`item.status`]="{ item }">
          <span
            class="label font-weight-bold label-lg label-inline"
            :class="['label-light-' + transactionStatuses[item.attributes.status]]"
          >
            {{ item.attributes.status }}
          </span>
        </template>
        <template #[`item.total`]="{ item }">
          <div :class="['text-' + transactionStatuses[item.attributes.status]]">
            {{ moneyDollarForamter(get(item, 'attributes.application.data.attributes.full_fee')) }}
          </div>
        </template>
      </cs-table>
    </template>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'profile-transactions',
  data() {
    return {
      submiting: false,
      loading: false,
      modal: false,
      headers: [
        {
          text: 'Date',
          value: 'attributes.paid_date',
          falsy: '-',
        },
        // {
        //   text: 'Bank',
        //   value: 'bank',
        //   falsy: '-',
        // },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
        },
        {
          text: 'Total',
          value: 'total',
          align: 'center',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      items: 'transactions/items',
      pagination: 'transactions/pagination',
    }),
    invoiceHeaders() {
      let data = [
        { name: 'Application fee', key: 'coordinator_fee' },
        { name: 'Background fee', key: 'background_fee' },
        { name: 'Convenience fee', key: 'fixed_appexpress_fee' },
        // { name: 'Cooperative fee', key: 'cooperative_fee' },
        // { name: 'Managing Agent fee', key: 'managing_agent_fee' },
        // { name: 'Stripe fee', key: 'stripe_fee' },
        { name: 'Total', key: 'full_fee', classes: 'd-block d-md-inline font-size-h3 ml-10 text-primary' },
      ];

      if (!this.get(this.transaction, 'attributes.application.data.attributes.background_fee', 0))
        data = data.filter((i) => i.key != 'background_fee');

      return data;
    },
    userData() {
      return this.$store.state.auth.user;
    },
    transaction() {
      let data = {};
      if (this.items.length && this.items[this.items.length - 1].attributes.status === 'Succeeded') {
        data = this.items[this.items.length - 1];
      }
      return data;
    },
  },

  async mounted() {
    await this.handleItems();
    const name = [this.userData.first_name || '', this.userData.last_name || ''].filter((e) => e != '');
    this.$root.$emit('changeTitle', name.join(' ') || this.userData.email);
  },

  methods: {
    ...mapActions({
      getItems: 'transactions/index',
    }),
    printInvoice() {
      window.print();
    },
    async handelFiltering(filters) {
      const routeQuery = this.$route.query;

      const isSame = this.isSame(routeQuery, filters);

      if (isSame) return;

      await this.$router.replace({ query: filters });

      await this.handleItems();
    },
    handleItems: debounce(async function(e) {
      this.loading = true;

      const query = this.$_.cloneDeep(this.$route.query);
      query.elasticsearch = true;

      if (query.where == undefined) query.where = {};

      if (query.status != undefined) query.where.status = query.status;

      if (query.page == undefined) query.page = 1;

      if (query.per == -1) query.per = Math.pow(10, 12);

      await this.$await(this.getItems(query));

      this.loading = false;
    }, 400),
  },
};
</script>
